<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物合笼</h3>
    <div class="detail">
      <el-form ref="addForm" size="small" :model="cage" class="addForm" label-suffix=":"
               :disabled="pageType == 3"
               label-width="80px">
        <h3 class="tit">动物合笼(申请笼舍数量：{{ activeInfo.cageNum }})</h3>
        <!--        :closable="activeInfo.isNotApproval==1 && pageType == 1 ? true : false"  @tab-remove="removeTab"-->
        <el-tabs v-model="cage.cageId" class="tabsCage" type="card" @tab-click="clickTab"
        >
          <el-tab-pane
              v-for="(item,i) in cage.tabs"
              :key="i"
              :label="item.number?item.number:'-'"
              :name="String(item.id)"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="flex head" v-if="activeInfo.isNotApproval==1 && pageType == 1">
          <el-form-item label="笼舍编号" prop="number">
            <el-input v-model="cage.number" clearable placeholder="请输入笼舍编号" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="笼架编号" prop="frame_no">
            <el-input v-model="cage.frame_no" clearable placeholder="请输入笼架编号" class="smallWidth"></el-input>
          </el-form-item>
          <el-button size="small" @click="addTab" style="margin-bottom: 18px;"
                     class="addTab add-box" icon="el-icon-plus"></el-button>
        </div>
        <div class="desc" v-if="activeInfo.isNotApproval==0">
          <span>笼架编号：{{ cage.frameNo }}</span>
        </div>
        <el-table
            :data="cage.aniBase" border
            class="tableMember" max-height="500"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column label="耳号" min-width="80">
            <template slot-scope="scope">
              <!-- allow-create -->
              <template v-if="scope.row.show && scope.row.id == ''">
                <el-select v-model="scope.row.overbit" filterable size="small" placeholder="请选择耳号"
                           @change="changeOverbit($event,scope.row,scope.$index)"
                           v-if="activeInfo.isLabAni == 0">
                  <el-option
                      v-for="(item,i) in overbitList"
                      :key="i"
                      :label="item.overbit"
                      :value="String(item.id)"
                      :disabled="item.disabled">
                  </el-option>
                </el-select>
                <el-input v-else v-model="scope.row.overbit" clearable placeholder="请输入耳号"
                          class="smallWidth"></el-input>
              </template>
              <span v-else>{{ scope.row.overbit ? scope.row.overbit : scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="基因类型"
              v-if="activeInfo.aniOrder.isGene==1"
              show-overflow-tooltip>
            <el-table-column v-for="(obj,index) in typeList" :key="index"
                             :label="obj.title"
                             min-width="110">
              <template slot-scope="scope">
                <template v-if="scope.row.show && activeInfo.isLabAni == 1">
                  <el-input size="small" placeholder="基因类型" v-model="scope.row.typeList[index].type"></el-input>
                </template>
                <span v-else>{{ scope.row.typeList[index].type }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
              label="性别" width="100"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.gender == 0 ? '♀' : '♂' }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="出生日期"
              width="240">
            <template slot-scope="scope">
              <template v-if="scope.row.show && activeInfo.isLabAni == 1">
                <el-date-picker :picker-options="pickerOptions" style="width: 160px"
                                @change="selectBir($event,scope.row,scope.$index)"
                                v-model="scope.row.birth_date" size="small"
                                type="date"
                                placeholder="请选择出生日期">
                </el-date-picker>
              </template>
              <template v-else-if="scope.row.birth_date"> {{ scope.row.birth_date |  formatDay }}</template>
              <template v-else>无</template>
            </template>
          </el-table-column>
          <el-table-column
              label="周龄" prop="weekDays"
              show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.weekDays ? scope.row.weekDays : 0 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="备注" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-if="scope.row.show" size="small" placeholder="备注" v-model="scope.row.remark"></el-input>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" v-if="!scope.row.show" @click="edit(scope.row,scope.$index)">修改</el-button>
                <el-button type="text" v-if="scope.row.show" @click="save(scope.row,scope.$index)">保存</el-button>
                <el-button type="text" v-if="!scope.row.show" @click="copy(scope.row,scope.$index)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.row,scope.$index)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="fromSave" v-if="pageType != 3">
          <el-button type="primary" size="small" @click="cageSubmit('1')">合笼完成</el-button>
          <el-button plain type="primary" size="small" @click="cageSubmit('0')">保存</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="编辑笼舍"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogFormVisible"
        width="400px">
      <el-input v-model="diaInfo.number" autocomplete="off" style="margin-bottom: 18px"
                placeholder="请输入笼舍编号"></el-input>
      <el-input v-model="diaInfo.frameNo" autocomplete="off" placeholder="请输入笼架编号"></el-input>
      <div slot="footer">
        <el-button size="small" type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

//表格初始
const tableBegin = [
  {
    typeList: [],
    gender: "",
    overbit: "",
    frameNo: "",
    cageNo: "",
    count: "",
    symptom: "",
    show: true,
    weekDays: "",
    birth_date: "",
    remark: "",
    id: ""
  }
]
const typeData = {readonly: false, title: "", type: ""}

export default {
  name: "point",
  props: ['activeInfo'],
  inject: ['reload'],
  data() {
    return {
      pageType: 1,//页面状态，1未保存未提交，2草稿,3提交后
      detail: {},//合笼页面详情数据
      status: this.activeInfo.status,//课题状态
      cage: {
        frameNo: "",
        aniBase: [],
        tabIndex: 0,
        tabs: [],
        number: '',//笼舍编号
        frame_no: '',//笼架编号
        nowNumber: '',//当前笼舍编号
        cageId: '',//笼舍id
      },
      options: [
        {
          value: 1,
          label: '雌性'
        }, {
          label: '雄性',
          value: 0
        }
      ],
      cageList: [],//笼舍数据
      typeList: [],
      overbitList: [],//耳号列表
      mateAniCageList: [],//存放动物列表
      tableDataSave: [],//保存后的动物数据，用于回填
      dataUrl: '',//接口地址
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      dialogFormVisible: false,//弹窗
      diaInfo: {
        number: '',
        frameNo: '',
        cageId: ''
      }
    }
  },
  watch: {},
  mounted() {
    if (this.activeInfo.status == 31) {
      this.dataUrl = "/mate/aniCage/submit/";//合笼中
      this.pageType = 2;
    } else if (this.activeInfo.status == 30) {
      this.dataUrl = "/subject/order/mate/";//去合笼
      this.pageType = 1;
    } else {
      this.dataUrl = "/mate/aniCage/submit/";//合笼提交后
      this.pageType = 3;
    }
    this.getData();
  },
  methods: {

    //获取数据,去合笼
    getData() {
      this.$get(this.dataUrl + this.activeInfo.id, {isLabAni: this.activeInfo.isLabAni})
          .then(res => {
            this.detail = res.data;
            if (this.activeInfo.status == 30) {
              //去合笼
              this.cageList = res.data.cageList;
              this.dataLogic();
            } else {
              //草稿31, 提交<31
              this.getCageList()
            }

          }).catch((err) => {
        this.$message.error("加载数据失败")
      })
    },
    //笼舍编号数据
    getCageList() {
      let that = this;
      this.$get("/subject/cage/" + this.activeInfo.id).then(res => {
        // console.log(res)
        if (res.status == 200) {
          res.data.forEach((item, index) => {
            item.id = String(item.id)
          })
          this.cageList = res.data;
          //保存后回填，根据对应笼舍id，获取动物数据
          this.$get("/mate/aniCage/" + res.data[this.cage.tabIndex].id, {isLabAni: this.activeInfo.isLabAni}).then(data => {
            /*data.data.forEach((item, index) => {
              console.log(item)
              // item.gene = item.orderDetail ? item.aniQuarResult.gene : '[]';
            })*/
            this.tableDataSave = data.data;
            if (this.activeInfo.status == 31) {
              that.dataLogic();
            } else {
              let lists = [];
              data.data.forEach((item) => {
                item.typeList = JSON.parse(item.aniQuarResult.gene);
                lists = item.aniQuarResult.gene ? JSON.parse(item.aniQuarResult.gene) : [];
                item.overbit = item.aniQuarResult.overbit;
              })
              this.typeList = lists;// 基因类型
              this.cage.aniBase = data.data;
              this.cage.tabs = this.cageList;
              //默认第一个
              this.cage.frameNo = this.cageList[this.cage.tabIndex].frameNo;
              this.cage.cageId = String(this.cageList[this.cage.tabIndex].id);
              this.cage.nowNumber = this.cageList[this.cage.tabIndex].number;
            }

          })
        } else {
          this.$message.error("获取笼舍数据失败")
        }
      })
    },
    //数据处理
    dataLogic() {
      //检疫合格动物列表
      if (this.activeInfo.isLabAni == 1 && this.detail.aniOrderDetailList) {
        this.overbitList = this.detail.aniOrderDetailList.length > 0 ? this.detail.aniOrderDetailList : [];
      } else if (this.activeInfo.isLabAni == 0 && this.detail.quarResultList) {
        this.overbitList = this.detail.quarResultList.length > 0 ? this.detail.quarResultList : [];
      }
      this.cageList.forEach((item, index) => {
        tableBegin[0].cageId = item.id;//笼舍id
        tableBegin[0].quarResultId = 0;//检疫id or 动物id
        if (this.activeInfo.status > 30) {
          // this.mateAniCageList[this.cage.tabIndex] = this.tableDataSave;
        } else {
          this.mateAniCageList[index] = JSON.parse(JSON.stringify(tableBegin))
        }
      })
      this.cage.tabs = this.cageList;

      //默认第一个
      this.cage.frameNo = this.cageList[this.cage.tabIndex].frameNo;
      this.cage.cageId = String(this.cageList[this.cage.tabIndex].id);
      this.cage.nowNumber = this.cageList[this.cage.tabIndex].number;


      if (this.overbitList && this.overbitList.length > 0) {
        this.overbitList.forEach(obj => {
          if (this.activeInfo.status > 30) {
            this.tableDataSave.forEach((data) => {
              if ((data.orderDetail && obj.id) == data.orderDetail.id) {
                obj.disabled = true;//已选取的禁用
              }
            })
          }
        })
      }

      if (this.pageType == 2 && this.tableDataSave.length > 0) {
        this.tableDataSave.forEach((item, index) => {
          if (item.aniQuarResult) {
            item.aniQuarResult.show = false;
            item.aniQuarResult.anId = item.id;
            item.aniQuarResult.remark = item.remark;
            // item.aniQuarResult.typeList = JSON.parse(item.gene)
            this.cage.aniBase.push(item.aniQuarResult);
          } else {
            item.aniQuarResult = {
              show: false,
              anId: item.id,
              remark: item.remark,
              typeList: this.typeList
            }
            this.cage.aniBase.push(item.aniQuarResult);
          }
        })
      } else {
        if (this.status === 30) {
          this.getTypeList()
        }
        tableBegin[0].typeList = this.typeList;
        this.cage.aniBase = JSON.parse(JSON.stringify(tableBegin));//数据选择栏
      }

    },
    //tab切换
    clickTab(ev) {
      this.cage.frameNo = this.cageList[ev.index].frameNo;//笼架编号
      this.cage.nowNumber = this.cageList[ev.index].number;

      if (this.activeInfo.isNotApproval == 1 && (this.cage.frameNo == null || this.cage.nowNumber == null)) {
        this.dialogFormVisible = true
        this.diaInfo = {
          cageId: this.cageList[ev.index].id,
          frameNo: this.cageList[ev.index].frameNo,
          number: this.cageList[ev.index].number
        }
      }

      if (this.pageType == 2 || this.pageType == 3) {
        this.cage.tabIndex = ev.index;//笼舍列表下标
        this.cage.aniBase = [];
        this.getCageList();
      } else {
        //保存上一个tab数据，回填下一tab数据
        this.mateAniCageList[this.cage.tabIndex] = this.cage.aniBase;
        this.cage.aniBase = this.mateAniCageList[ev.index];
        this.cage.tabIndex = ev.index;//笼舍列表下标
      }
    },
    // 提交编辑好的
    submit() {
      let params = {
        number: this.diaInfo.number,//笼舍编号
        sub_id: this.activeInfo.id,//课题id
        is_product: this.activeInfo.isProd,//0：实验方案，1：生产方案
        frameNo: this.diaInfo.frameNo,
        id: this.diaInfo.cageId,//笼舍id
      }
      this.$put('/subject/cage', params).then(res => {
        if (res.status == 200) {
          this.dialogFormVisible = false
          this.reload();
        }
      })
    },
    //选择耳号
    changeOverbit(value, row, index) {
      this.overbitList.forEach((item, index) => {
        if (item.id == value) {
          //回填对应小鼠数据
          row.gender = item.gender
          row.birth_date = item.birthDate
          row.typeList = JSON.parse(item.gene)
          row.subId = item.subId
          row.cageId = this.cage.cageId
          row.overbit = item.overbit
          //判断是否是实验室，是就是检疫id，不是就动物id
          if (this.activeInfo.isLabAni == 0) {
            row.quarResultId = item.id
          }
          this.selectBir(item.birthDate, row)
        }
      })
      this.$set(this.cage.aniBase, index, row)
    },
    // 获取基因类型
    getTypeList() {
      // 赋值typeList
      let arr = this.detail.geneKeys.split(',')
      arr.forEach(item => {
        this.typeList.push({
          title: item,
          type:''
        })
      })
    },
    //show:true保存
    save(row, index) {
      if (this.pageType == 2 && row.show) {
        if (!row.id || row.id == '') {
          this.addTeam(row, index);//接口新增
        } else {
          // 接口修改,未提交合笼
          let params = {
            id: row.anId,
            remark: row.remark
          };
          this.getEdit(row, index, params);
        }
      } else {
        //保存上一个tab数据，回填下一tab数据
        this.mateAniCageList[this.cage.tabIndex] = this.cage.aniBase;
        if (row.overbit == '' && row.show) {
          this.$message.warning("请选择耳号！")
          return;
        }
        this.overbitList.forEach((item, index) => {
          if (item.id == row.quarResultId) {
            item.disabled = true//关闭耳号
          }
        })
        this.publicShow(row, index);
      }
    },
    // 编辑,show:false修改
    edit(row, index) {
      if (row.overbit == '' && row.show) {
        this.$message.warning("请选择耳号！")
        return;
      }
      this.overbitList.forEach((item, index) => {
        if (item.id == row.quarResultId) {
          item.disabled = true//禁用掉已选耳号
        }
      })
      this.publicShow(row, index);
    },
    //接口，修改
    getEdit(row, index, params) {
      this.$putJson("/mate/aniCage", params).then(res => {
        this.publicShow(row, index);
        this.$message.success('修改成功！')
      })
    },
    // 选择出生日期 改变周龄
    selectBir(value, row, index) {
      if (value) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        let birDay = moment(value).format("YYYY-MM-DD");
        let diffTime = moment(today).diff(moment(birDay), 'days');
        let weeksAge = diffTime / 7
        row.weekDays = Math.round(weeksAge)
      }
    },
    // 复制
    copy(val, index) {
      let data = JSON.parse(JSON.stringify(val));
      //新增空对象
      let obj = JSON.parse(JSON.stringify(val));
      for (var key in obj) {
        obj[key] = '';
      }
      obj.show = true;
      obj.cageId = this.cage.cageId;
      obj.typeList = data.typeList;
      this.cage.aniBase.splice(index, 0, obj)
    },
    // 删除，判断
    deleteA(row, index) {
      if (this.pageType == 2) {
        //接口删除
        if (row.anId) {
          this.$del("/mate/aniCage/" + row.anId).then(res => {
            this.publicDel(row, index);
            this.$message.success('删除成功！')
          }).catch(() => {
            this.$message.error("删除失败！")
          })
        }
      } else {
        this.publicDel(row, index);
      }
    },
    //删除
    publicDel(row, index) {
      if (this.cage.aniBase.length == 1) {
        //只有一条，初始
        this.cage.aniBase = JSON.parse(JSON.stringify(tableBegin));
      } else {
        this.cage.aniBase.splice(index, 1)//list删除
      }
      this.overbitList.forEach((item, index) => {
        if (item.overbit == row.overbit) {
          item.disabled = false//开启耳号
        }
      })
    },
    //添加笼舍
    addTab(targetName) {
      if (this.cage.number == '') {
        this.$message.warning("请先输入笼舍编号！")
        return;
      }
      if (this.cage.frame_no == '') {
        this.$message.warning("请先输入笼架编号！")
        return;
      }
      this.cage.tabs.push({
        number: this.cage.number,
        name: String(this.cage.tabs.length + 1)
      });
      let params = {
        number: this.cage.number,
        subId: this.activeInfo.id,//课题id
        isProduct: this.activeInfo.isProd,//0：实验方案，1：生产方案
        frameNo: this.cage.frame_no
      }
      //接口新增
      this.$post("/subject/cage", params).then(res => {
        this.$message.success("添加成功")
        this.reload();
        //初始化
        this.cage.number = ''
        this.cage.frame_no = ''
      }).catch(() => {
        this.$message.error("添加失败")
      })

    },
    //删除笼舍
    removeTab(targetName) {
      /*console.log(this.cage.tabs)
      console.log(this.tabIndex)*/
      let tabs = this.cage.tabs;
      let activeName = this.cage.tabs[this.tabIndex].name;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
            //删除对应动物列表,回填一下tab表格数据
            this.mateAniCageList.splice(1, index);
            this.tabIndex += 1;
            this.cage.aniBase = this.mateAniCageList[this.tabIndex];
          }
        });
      }
      this.cage.tabs = tabs.filter(tab => tab.name !== targetName);

    },
    //和笼前，新增一条动物数据
    addTeam(row, index) {
      //接口添加,cageId quarResultId subId quarResultId orderId不为空
      let reg = new RegExp('/', 'g')//g代表全部
      let params = {
        cageId: this.cage.cageId,
        subId: this.detail.subId,
        gender: row.gender,
        overbit: row.overbit,
        frameNo: this.cage.frameNo,
        cageNo: this.cage.nowNumber,
        // birthTime: formatTimer(row.birth_date).replace(reg, '-'),
        gene: JSON.stringify(row.typeList),
        remark: row.remark,
        quarResultId: row.quarResultId
      }
      this.$postJson("/mate/aniCage", params).then(res => {
        this.publicShow();
      }).catch(() => {
        this.$message.error("保存失败!")
      })
    },
    //关闭 or 开启，/**公共代码**/
    publicShow(row, index) {
      row.show = row.show ? false : true
      this.$set(this.cage.aniBase, index, row)
    },

    //合笼完成1,保存0
    cageSubmit(type) {
      if (this.pageType == 2) {
        //草稿，将合笼状态改为提交
        let params = {
          id: this.detail.id,
          state: type,
          // isLabAni:this.detail.isLabAni//是否来自实验室
        }
        this.$put("/subject/mate/state", params).then(res => {
          this.$message.success("操作成功！")
        }).catch(() => {
          this.$message.error("操作失败")
        })
      } else {
        //直接提交
        let lists = [];
        this.mateAniCageList.forEach((item, index) => {
          //数据处理
          if (item.length > 0) {
            item.forEach((item2, index2) => {
              if (item2.overbit != '') {
                let obj = {
                  cageId: item2.cageId,//笼舍id
                  subId: item2.subId,//课题id
                  //判断是否是实验室，是就是检疫id，不是就动物id
                  quarResultId: item2.quarResultId,//检疫结果id
                  remark: item2.remark,
                }
                lists.push(obj)
              }
            })
          }
        })
        if (lists.length == 0) {
          this.$message.warning('请选择动物信息！')
          return false;
        }
        let params = {
          mateAniCageList: lists,
          state: type,
          subId: this.detail.subId,
          isLabAni: this.detail.isLabAni//是否来自实验室
        }
        this.$postJson("/subject/mate", params).then(res => {
          this.$message.success("操作成功！")
          this.$router.go(-1)
        }).catch(() => {
          this.$message.error("操作失败")
        })
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
